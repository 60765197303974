import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Popconfirm, Select, Table, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingGeneralValue} from '../../Services';
import _ from 'lodash';
import { _capitalize } from 'chart.js/helpers';
import { splitStringIntoTwoLines } from '../../Helpers/ValueFormater';
import { ListingGeneralKeysThunk, ListingGeneralValueThunk } from '../../Store/Thunks/ListingGeneralThunk';
import { amlOptions, communityMediaOptions, foundersTeamOptions, lawsuitsOptions, reputationOptions } from './StaticTexts/dropdown';
import ReloadIcon from "../../Assets/img/icons/refresh.png";

const keysToCheck = [
  "foundersOrTeam",
  "reputation",
  "aml",
  "lawsuits",
  "communityOrMedia"
];
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ListingGeneralValueData,
  ...restProps
}) => {
 
  const options  =  dataIndex == "foundersOrTeam" 
                    ?  foundersTeamOptions 
                    : dataIndex == "reputation" 
                    ? reputationOptions
                    : dataIndex == "lawsuits" 
                    ? lawsuitsOptions 
                    : dataIndex == "aml"
                    ? amlOptions 
                    :communityMediaOptions
                    const isHasKey = keysToCheck.includes(dataIndex);

  return (
    <td {...restProps}>
    {editing ? (
      isHasKey ? (
        <>
          <Form.Item name={`${dataIndex}Level`} style={{ margin: 0 }}>
            <Select>
              {Object.keys(options).map(key => (
                <Select.Option key={options[key].value} value={options[key].value} style={options[key].style}>
                  {options[key].label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            <Input />
          </Form.Item>
        </>
      ) : (
        record ? (
          <>
           <Form.Item name={dataIndex} style={{ margin: 0 }}>
            <Input />
          </Form.Item>
            {/* <p style={{ fontWeight: 700 }}>{record[`${dataIndex}Level`]}</p>
            <p>{record[dataIndex]}</p>             */}
          </>
        ) : (
          children
        )
      )
    ) : (
      children
    )}
  </td>
  );
};

export default EditableCell;

export const General = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [modifiedRows, setModifiedRows] = useState(new Set());
  const [cellToggleState, setCellToggleState] = useState({}); // Track each cell's state individually

  useEffect(() => {
    dispatch(ListingGeneralKeysThunk());
    dispatch(ListingGeneralValueThunk());
  }, []);

  const {
    ListingGeneralKeyData,
    ListingGeneralKeyLoading,
    ListingGeneralValueData,
    ListingGeneralValueLoading,
  } = useSelector((state) => state.ListingGeneralReducer);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {     
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const dataSource = () => {
    const arr = [];
    ListingGeneralValueData.map((item, index) => {    
      const obj = {};
      ListingGeneralKeyData.map((element) => {
        const key = element.key;
        const manualKey = `${key}Manual`;
        const cellKey = `${item.listingGeneral.id}_${key}`;
        if (item.listingGeneral) {         
          obj[element.key] = item.listingGeneral[element.key];
          if( (element.key !== "telegram" && element.key !=="reddit" && element.key !=="twitter")){
          obj[`${element.key}Level`] = item.listingGeneral[`${element.key}Level`];
        
          }
          const manualDataExists = item.listingGeneral[manualKey] && item.listingGeneral[manualKey] != 0;
          obj[key] =  !manualDataExists
            ? item.listingGeneral[key]  // Automatic data
            : item.listingGeneral[manualKey]; // Manual data
    
          // Store the hasManualData flag for each cell
          obj[`${key}_hasManualData`] = manualDataExists ? true : false;
          obj.id = item.listingGeneral.id;
        } else {       
          obj[element.key] = "";
          if( (element.key !== "telegram" && element.key !=="reddit" && element.key !=="twitter")){
          obj[`${element.key}Level`] = "";
          }
          obj.id = item.id;
        }
      });
      
      arr.push({
        name: item.assetName,
        ...obj,
        key: index,
      });
    });
      
    setData(arr);
    return arr;
  };

  

  useEffect(() => {
    if (ListingGeneralKeyData?.length && ListingGeneralValueData.length) {
      dataSource();
    }
  }, [ListingGeneralKeyData, ListingGeneralValueData,cellToggleState]);

  // const editAssetValue = () => {
  //   setLoading(true);
  
  //   // Use reduce to process each modified row and cell
  //   const editData = Array.from(modifiedRows).reduce((acc, cellId) => {
  //     if (typeof cellId === "string") {
  //       const [rowId, colKey] = cellId.split('_');
  //       const row = data.find((item) => item.id === parseInt(rowId, 10));
  //       if (!row) return acc;
  
  //       console.log(acc,"dddd")
  //       // Find or initialize an item in acc for this row ID
  //       let newItem = acc.find((item) => item.id === row.id);
  //       if (!newItem) {
  //         newItem = { id: row.id }; // Initialize a new item for this row if not found
  //         acc.push(newItem);
  //       }
  //       else{
  //         acc.push(newItem)
  //       }
  
  //       // Set only modified fields in newItem
  //       const value = row[colKey];
  //       const manualKey = `${colKey}Manual`;
  
  //       // Handling fields that require special treatment
  //       if (colKey === 'foundersOrTeam' || colKey === 'reputation' || colKey === 'aml' ||
  //           colKey === 'lawsuits' || colKey === 'communityOrMedia') {
  //         newItem[colKey] = value;  // Directly assign without conversion
         
  //       } else {
  //         // For all other keys, we convert them to "Manual" fields
  //         if (colKey === 'telegram') {
  //           newItem[manualKey] = value ? Number(value) : 0;
  //         } else if (colKey === 'reddit') {
  //           newItem[manualKey] = value ? Number(value) : 0;
  //         } else if (colKey === 'twitter') {
  //           newItem[manualKey] = value ? Number(value) : 0;
  //         } else if (colKey === 'githubCommits') {
  //           newItem[manualKey] = value ? Number(value) : 0;
  //         } else if (colKey === 'googleSearchTrend') {
  //           newItem[manualKey] = value ? Number(value) : 0;
  //         } else {
  //           // Handle other cases if needed
  //           newItem[colKey] = value;  // default manual key assignment for other columns
  //         }
  //       }
  //     }
  
  //     return acc;
  //   }, []);
  
  //   // Call your API with the accumulated edit data
  //   ApiPutListingGeneralValue({ generals: editData })
  //     .then(() => {
  //       setLoading(false);
  //       setModifiedRows(new Set());
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // };
  
  const editAssetValue = () => {
    setLoading(true);
  
    const editData = Array.from(modifiedRows).reduce((acc, cellId) => {
      if (typeof cellId === "string") {
        const [rowId, colKey] = cellId.split('_');
        const row = data.find((item) => item.id === parseInt(rowId, 10));
        if (!row) return acc;
  
        // Find or initialize an item in acc for this row ID
        let newItem = acc.find((item) => item.id === row.id);
        if (!newItem) {
          newItem = { id: row.id }; // Initialize a new item for this row if not found
          acc.push(newItem);
        }
  
        // Set only modified fields in newItem
        const value = row[colKey];
        const manualKey = `${colKey}Manual`;
  
        // Handling fields that require special treatment
        if (['foundersOrTeam', 'reputation', 'aml', 'lawsuits', 'communityOrMedia'].includes(colKey)) {
          newItem[colKey] = value;  // Directly assign without conversion
        } else {
          // Convert to "Manual" fields for specific keys
          if (['telegram', 'reddit', 'twitter', 'githubCommits', 'googleSearchTrend'].includes(colKey)) {
            newItem[manualKey] = value ? Number(value) : 0;
          } else {
            // Default case for other columns
            newItem[colKey] = value;
          }
        }
      }
      return acc;
    }, []);
  
    if (editData.length > 0) {
      ApiPutListingGeneralValue({ generals: editData })
        .then(() => {
          setLoading(false);
          setModifiedRows(new Set());
          setIsSaveClicked(true);

          dispatch(ListingGeneralKeysThunk());
          dispatch(ListingGeneralValueThunk());
        })
        .catch(() => {
          setLoading(false);
          setIsSaveClicked(false);
        });
    } else {
      setLoading(false); // Handle the case where there is no data to save
    }
  };
  
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });        
        setData(newData);
        // setModifiedRows(prev => new Set(prev).add(item.id)); 

        Object.keys(row).forEach(colKey => {
          if (row[colKey] !== item[colKey]) { // Only if value has changed
            setModifiedRows(prev => new Set(prev).add(`${item.id}_${colKey}`));
          }
        });
        setEditingKey('');
      }
      else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      } 
     
    } catch (errInfo) {
      console.log('Validate Failed: ', errInfo);
    }
    setIsSaveClicked(true);
    // dispatch(ListingGeneralKeysThunk());
    // dispatch(ListingGeneralValueThunk());

  };
  
  useEffect(() => {
    dispatch(ListingGeneralKeysThunk());
    dispatch(ListingGeneralValueThunk());
  }, [isSaveClicked]);

  useEffect(()=>{
    if(modifiedRows && isSaveClicked){
      editAssetValue();
    }
    setIsSaveClicked(false)
  },[modifiedRows,isSaveClicked])

  // Handle toggle for each cell
// const handleToggleCellData = (rowId, colKey) => {
//   const cellKey = `${rowId}_${colKey}`;
//   setCellToggleState((prevState) => ({
//     ...prevState,
//     [cellKey]: !prevState[cellKey],
//   }));
// };



// Handle toggle for each cell
const handleToggleCellData = async (rowId, colKey) => {
  const cellKey = `${rowId}_${colKey}`;
  setCellToggleState((prevState) => ({
    ...prevState,
    [cellKey]: !prevState[cellKey],
  }));

// Prepare data to remove manual entry
const row = data.find((item) => item.id === rowId);
if (!row) return;

const manualKey = `${colKey}Manual`;
const newItem = { id: row.id, [manualKey]: null }; // Remove manual data

// Call API to remove manual data
   setLoading(true);
   try {
     await ApiPutListingGeneralValue({ generals: [newItem] });
     setModifiedRows((prev) => {
       const newSet = new Set(prev);
       newSet.delete(cellKey); // Remove the cell from modified rows after save
       return newSet;
     });
      // Fetch updated data from the backend
      dispatch(ListingGeneralKeysThunk());
      dispatch(ListingGeneralValueThunk());
 
   } catch (error) {
     console.error("Failed to update manual data:", error);
   } finally {
     setLoading(false);
   }
};

  const columns = () => {
    return [
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed: 'left',
        width: 100,

      },
      ...ListingGeneralKeyData.map((item) => ({
        title: item.key !== 'twitter' ? splitStringIntoTwoLines(item.name) :item.name,
        dataIndex: item.key,
        editable: true,
        // width: 100, 
        // render: (text, record) => (
        //   <div>
        //     {/* Display main field */}
        //     <p>{text}</p>

        //     {/* Display corresponding Level field if it exists */}
        //     {record[`${item.key}Level`] && (
        //       <p style={{ fontWeight: 'bold' }}>{record[`${item.key}Level`]}</p>
        //     )}
        //   </div>
        // ),
        render: (text, record) => {
          const formatNumber = (value) => {
            return Number(value)
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          };
          const hasManualData = record[`${item.key}_hasManualData`];
          const cellKey = `${record.id}_${item.key}`;
          const isManualDataDisplayed = cellToggleState[cellKey];
          return (
            <>
              {record[`${item.key}Level`] && (
                <p style={{ fontWeight: 'bold' }}>{record[`${item.key}Level`]}</p>
              )}
        <span style={{ color: (hasManualData ) ? "#1890ff" : "#000" }}>
              {text && /^\d+(\.\d+)?$/.test(text) ? (
                text !== 0 ? formatNumber(text) : null
              ) : ( 
                text
              )}
        </span>
              {hasManualData && (
                <img
                  src={ReloadIcon}
                  alt="Reload"
                  style={{
                    width: "20px",
                    position: 'absolute',
                    top: "15px",
                    right: "10px",
                    transform: 'translateY(-50%)'
                  }}
                  onClick={() => handleToggleCellData(record.id, item.key)}
                />
              )}
            </>
          );
        }
        
        
      })),
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (_, record) => {
          const editable = isEditing(record);         
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
                <a>Cancel</a>               
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
          );
        },
      },
    ];
  };

  const mergedColumns = columns().map((col) => {
    
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ListingGeneralValueData
      }),
    };
  });



  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
      <Form form={form} component={false} >
        <Table
          loading={ListingGeneralKeyLoading || ListingGeneralValueLoading || loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className="custom-scroll"
          pagination={false}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          // scroll={{
          //   x: '100vw', 
          //   y: 700, 
          // }}
          scroll={{ y: 500 }}
        />
      </Form>
    </div>
  );
};
