import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Table, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingMaturityValue } from '../../Services';
import { ListingMaturityKeysThunk, ListingMaturityValueThunk } from '../../Store/Thunks/ListingMaturityThunk';
import _ from 'lodash';
import { _capitalize } from 'chart.js/helpers';
import { splitStringIntoTwoLines, stringToNumber } from '../../Helpers/ValueFormater';
import ReloadIcon from "../../Assets/img/icons/refresh.png"
const keysToCheck = [
  "projectMaturityParameter",
  "durationOfProjectsExistence",
  "durationOfDevelopersFullTime",
  "durationOfDevelopersTotal"
];
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  cellToggleState,
  handleToggleCellData,
  ...restProps
}) => {
  let inputNode;
  const options = {
    mature: { value: "mature", style: { color: '#4DC7B5' }, label: "Mature" },
    medium: { value: "medium", style: { color: 'rgb(245, 180, 82)' }, label: "Medium" },
    immature: { value: "immature", style: { color: 'rgb(242, 114, 129)' }, label: "Immature" }
  };

  if (dataIndex === 'projectMaturityParameter') {
    inputNode = (
      <Select>
        {Object.keys(options).map((key) => (
          <Select.Option key={options[key].value} value={options[key].value} style={options[key].style}>
            {options[key].label}
          </Select.Option>
        ))}
      </Select>
    );
  } else {
    inputNode = inputType === 'number' ? (
      <InputNumber
        className="editable-field"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input
        className="editable-field"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    );
  }

  const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);
  const renderValueWithIcon = (value, record, dataIndex) => {
    const cellKey = `${record.id}_${dataIndex}`;
    const isManualData = cellToggleState[cellKey];
    const hasManualData = record[`${dataIndex}_hasManualData`];

   
    // Set displayValue to pull correct automatic or manual data with fallback for undefined cases
    const displayValue = isManualData
      ? record[`${dataIndex}Manual`] ?? value  // Fallback to value if manual data is unavailable
      : value ?? record[`${dataIndex}Manual`]; // Fallback to manual data if value is undefined
      const colorStyle = {
        color: hasManualData ? "#1890ff" : "#000", // Blue for manual, black for automatic
      };
    return (
      <span style={colorStyle}>
        {displayValue}
      </span>
    );
  };
  

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        <>
          {(dataIndex === 'projectMaturityParameter'||
           dataIndex === 'durationOfProjectsExistence' ||
           dataIndex === 'durationOfDevelopersFullTime' ||
           dataIndex === 'durationOfDevelopersTotal') && record[dataIndex] ? (
            capitalize(record[dataIndex])
          ) : dataIndex === 'averageNumberOfTransactionsPer7Days' && record[dataIndex] ? (
            record[dataIndex] !== 0 ? renderValueWithIcon(Number(record[dataIndex]).toLocaleString(), record, dataIndex) : null
          ) : (dataIndex === 'numberOfAddressesWithBalance' || dataIndex === 'newAddressesPer7Days') && record[dataIndex] ? (
            record[dataIndex] !== 0 ? renderValueWithIcon(stringToNumber(record[dataIndex]), record, dataIndex) : null
          ) : (dataIndex === 'sevenDayAverage' || dataIndex === 'ninetyDayAverage') && record[dataIndex] ? (
            record[dataIndex] !== 0 ? renderValueWithIcon(`$${Number(record[dataIndex]).toLocaleString()}`, record, dataIndex) : null
          ) : dataIndex === 'newAdoptionRate' && record[dataIndex] ? (
            record[dataIndex] !== 0 ? renderValueWithIcon(`${(record[dataIndex] * 100).toFixed(2)}%`, record, dataIndex) : null
          ) : dataIndex === 'volatility' && record[dataIndex] ? (
            record[dataIndex] !== "0" ? renderValueWithIcon(`${Number(record[dataIndex]).toFixed(2)}%`, record, dataIndex) : ""
          ) : (
            children
          )}
          
          {/* Display the ReloadIcon if hasManualData is true and the cell is untoggled */}
          {/* {record && record[`${dataIndex}_hasManualData`] && !cellToggleState[`${record.id}_${dataIndex}`] && ( */}
          {record && record[`${dataIndex}_hasManualData`] && (
            <img
              src={ReloadIcon}
              alt="Reload"
              style={{
                width: "20px",
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer"
              }}
              onClick={() => handleToggleCellData(record.id, dataIndex)}
            />
          )}
        </>
      )}
    </td>
  );
};

export default EditableCell;

export const Maturity = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [modifiedRows, setModifiedRows] = useState(new Set());
  const [cellToggleState, setCellToggleState] = useState({}); // Track each cell's state individually

  useEffect(() => {
    dispatch(ListingMaturityKeysThunk());
    dispatch(ListingMaturityValueThunk());
  }, []);

  const {
    ListingMaturityKeyData,
    ListingMaturityKeyLoading,
    ListingMaturityValueData,
    ListingMaturityValueLoading,
  } = useSelector((state) => state.ListingMaturityReducer);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {   
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const dataSource = () => {
    const arr = [];
    ListingMaturityValueData.map((item, index) => {    
      const obj = {};
      ListingMaturityKeyData.map((element) => {
        const key = element.key;
        const manualKey = `${key}Manual`;
        const cellKey = `${item.listingMaturity.id}_${key}`;
        if (item.listingMaturity) {         
          //obj[element.key] = (item.listingMaturity[element.key] && element.key=="projectMaturityParameter") ? _capitalize(item.listingMaturity[element.key]) : item.listingMaturity[element.key];
          // obj[element.key] = item.listingMaturity[element.key];
          const manualDataExists = item.listingMaturity[manualKey] && item.listingMaturity[manualKey] != 0;
          obj[key] =  !manualDataExists
            ? item.listingMaturity[key]  // Automatic data
            : item.listingMaturity[manualKey]; // Manual data
    
          // Store the hasManualData flag for each cell
          obj[`${key}_hasManualData`] = manualDataExists ? true : false;
          obj.id = item.listingMaturity.id;
        }
         else {     
            
          obj[element.key] = "";
          obj.id = item.id;
        }
      });
      arr.push({
        name: item.assetName,
        ...obj,
        key: index,
      });
    });
    setData(arr);
    return arr;
  };
  
  useEffect(() => {
    if (ListingMaturityKeyData?.length && ListingMaturityValueData.length) {
      dataSource();
    }
  }, [ListingMaturityKeyData, ListingMaturityValueData, cellToggleState]);


// Handle toggle for each cell
const handleToggleCellData = async (rowId, colKey) => {
  const cellKey = `${rowId}_${colKey}`;
  setCellToggleState((prevState) => ({
    ...prevState,
    [cellKey]: !prevState[cellKey],
  }));

// Prepare data to remove manual entry
const row = data.find((item) => item.id === rowId);
if (!row) return;

const manualKey = `${colKey}Manual`;
const newItem = { id: row.id, [manualKey]: null }; // Remove manual data

   // Call API to remove manual data
   setLoading(true);
   try {
     await ApiPutListingMaturityValue([newItem]);
     setModifiedRows((prev) => {
       const newSet = new Set(prev);
       newSet.delete(cellKey); // Remove the cell from modified rows after save
       return newSet;
     });
      // Fetch updated data from the backend
      dispatch(ListingMaturityKeysThunk());
      dispatch(ListingMaturityValueThunk());
 
   } catch (error) {
     console.error("Failed to update manual data:", error);
   } finally {
     setLoading(false);
   }
};

//  const editAssetValue = () => {
//   setLoading(true);

//   const editData = Array.from(modifiedRows).reduce((acc, cellId) => {
//     if (typeof cellId === "string") {
//       const [rowId, colKey] = cellId.split('_');
//       const row = data.find((item) => item.id === parseInt(rowId, 10));
//       if (!row) return acc;

//       // Find or initialize an item in acc for this row ID
//       let newItem = acc.find((item) => item.id === row.id);
//       if (!newItem) {
//         newItem = { id: row.id };
//         acc.push(newItem);
//       }

//       // Set only modified fields in newItem
//       const value = row[colKey];
//       const manualKey = `${colKey}Manual`;

//       if (colKey === 'sevenDayAverage' || colKey === 'ninetyDayAverage') {
//         newItem[manualKey] = value ? String(value) : "0";
//       } else if (colKey === 'numberOfAddressesWithBalance') {
//         newItem[manualKey] = value ? Number(value) : "0";
//       } else if (colKey === 'newAddressesPer7Days') {
//         newItem[manualKey] = value ? Number(value) : 0;
//       } else if (colKey === 'newAdoptionRate') {
//         newItem[manualKey] = value ? String(value) : "0";
//       } else if (colKey === 'averageNumberOfTransactionsPer7Days') {
//         newItem[manualKey] = value ? String(value) : "0";
//       } else if (colKey === 'volatility') {
//         newItem[manualKey] = value ? String(value) : "0";
//       } else {
//         // Handle other cases if needed
//         newItem[manualKey] = value;  // default manual key assignment
//       }
//     }

//     return acc;
//   }, []);

//   // Call your API with the accumulated edit data
//   ApiPutListingMaturityValue(editData)
//     .then(() => {
//       setLoading(false);
//       setModifiedRows(new Set());
//     })
//     .catch(() => {
//       setLoading(false);
//     });
// };

// const editAssetValue = () => {
//   setLoading(true);

//   const editData = Array.from(modifiedRows).reduce((acc, cellId) => {
//     const [rowId, colKey] = cellId.split('_');
//     const row = data.find((item) => item.id === parseInt(rowId, 10));
//     if (!row) return acc;

//     let newItem = acc.find((item) => item.id === row.id);
//     if (!newItem) {
//       newItem = { id: row.id };
//       acc.push(newItem);
//     }else{
//       acc.push(row)
//     }

//     const value = row[colKey];
//     const manualKey = `${colKey}Manual`;

//     newItem[manualKey] = value;
//     return acc;
//   }, []);

//   ApiPutListingMaturityValue(editData)
//     .then(() => {
//       setLoading(false);
//       setModifiedRows(new Set());
//     })
//     .catch(() => {
//       setLoading(false);
//     });
// };
const editAssetValue = () => {
  setLoading(true);

  const editData = [...data]
    .filter(row => Array.from(modifiedRows).some(cellId => cellId.startsWith(`${row.id}_`))) // Filter rows with modified cells
    .map(row => {
      const newItem = { id: row.id };
      Object.keys(row).forEach(colKey => {
        if (modifiedRows.has(`${row.id}_${colKey}`)) {
          // For modified cells, add the manual field
          // newItem[`${colKey}Manual`] = row[colKey];
          if (colKey === 'sevenDayAverage'){
                 newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";
                }
            else if (colKey === 'ninetyDayAverage') {
                   newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";
                  }
                   else if (colKey === 'numberOfAddressesWithBalance') {
                    newItem[`${colKey}Manual`] = row[colKey] ? Number(row[colKey]) : "0";
                  } else if (colKey === 'newAddressesPer7Days') {
                    newItem[`${colKey}Manual`] = row[colKey] ? Number(row[colKey]) : 0;
                  } 
                  else if(colKey ==="newAddressesPer7DaysManual"){
                    newItem[`${colKey}Manual`] = row[colKey] ? Number(row[colKey]) : 0;
                    console.log(Number(row[colKey]))                    
                  }else if (colKey === 'newAdoptionRate') {
                    newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";
                  } else if (colKey === 'averageNumberOfTransactionsPer7Days') {
                    newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";
                  } else if (colKey === 'volatility') {
                    newItem[`${colKey}Manual`] = row[colKey] ? String(row[colKey]) : "0";

        }
        else{
      
        newItem[`${colKey}`] = row[colKey];
      }
      }
    }
    );
      console.log(newItem,"newItem")
      return newItem;
    });
  ApiPutListingMaturityValue(editData)
    .then(() => {
      setLoading(false);
      setModifiedRows(new Set());
    })
    .catch(() => {
      setLoading(false);
    });
};



  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });        
        setData(newData);
        // setModifiedRows(prev => new Set(prev).add(item.id)); 
          // Track only modified cells
          Object.keys(row).forEach(colKey => {
            if (row[colKey] !== item[colKey]) { // Only if value has changed
              setModifiedRows(prev => new Set(prev).add(`${item.id}_${colKey}`));
            }
          });
        setEditingKey('');
      }   
      else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }  
      setIsSaveClicked(true)
   
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    
  };  
  useEffect(()=>{
    if(isSaveClicked)
    dispatch(ListingMaturityKeysThunk());
    dispatch(ListingMaturityValueThunk());
  },[isSaveClicked])

  useEffect(()=>{
    if(modifiedRows && isSaveClicked){
      editAssetValue();
    }
    setIsSaveClicked(false)
  },[modifiedRows,isSaveClicked])

  const columns = () => {
    return [
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed: 'left',
        width: 80,

      },
      ...ListingMaturityKeyData.map((item) => ({
        title: splitStringIntoTwoLines(item.name),
        dataIndex: item.key,
        editable: true,    
        // width:130,    
        inputType: item.key === 'projectMaturityParameter' ? 'dropdown' : 
        (item.key=='numberOfAddressesWithBalance' ||          
        item.key=='averageNumberOfTransactionsPer7Days' || 
        item.key=='sevenDayAverage' ||
        item.key=='newAdoptionRate' ||
        item.key=='ninetyDayAverage' ||
        item.key=='newAddressesPer7Days') ? 'number':  'text',
        render: (text, record) => {
          const hasManualData = record[`${item.key}_hasManualData`];
          const cellKey = `${record.id}_${item.key}`;
        
          return (
            <div style={{ position: 'relative' }}>
              {/* <span
                style={{
                  color: hasManualData && !cellToggleState[cellKey] ? "#1890ff" : "#000",
                }}
              >
                {/^[-\d.]+$/.test(text)
                  ? text && text !== "0"
                    ? Number(text).toLocaleString()
                    : null
                  : text}
              </span> */}
              {/* {hasManualData && !cellToggleState[cellKey] && (
                <img
                  src={ReloadIcon}
                  alt="Reload"
                  style={{
                    width: "20px",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                  onClick={() => handleToggleCellData(record.id, item.key)}
                />
              )} */}
            </div>
          );
        }    
      
      })),
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        // width: 110,
        render: (_, record) => {        
        
          const editable = isEditing(record);         
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
                <a>Cancel</a>               
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
        
          );
       
        },
      },
    ];
  };

  const mergedColumns = columns().map((col) => {
    
    if (!col.editable) {
      return col;      
    }
    
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });




  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
      <Form form={form} component={false} >
      <Table
  loading={ListingMaturityKeyLoading || ListingMaturityValueLoading || loading}
  components={{
    body: {
      cell: (props) => (
        <EditableCell
          {...props}
          cellToggleState={cellToggleState}
          handleToggleCellData={handleToggleCellData}
        />
      ),
    },
  }}
  style={{ width: '83vw' }}
  className="custom-scroll maturity"
  pagination={false}
  bordered
  dataSource={data}
  columns={mergedColumns}
  rowClassName="editable-row"
  scroll={{ y: 500 }}
/>
      </Form>
    </div>
  );
};
