import React, { useEffect, useMemo, useRef } from "react";
import { Tabs } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ShieldBox from "../../Components/ShieldBox";
import DefiShieldCard from "../../Components/DefiShieldCard/DefiShieldCard";
import {
  getDefishieldThunk,
  getUserShieldsThunk,
  userShieldThunk,
} from "../../Store/Thunks/UserCardsThunk";

import { UserCounterpartyRiskThunk } from "../../Store/Thunks/UserCounterpartyRiskThunk";

import { UserCounterpartyRiskCard } from "../../Components/UserCounterpartyRiskCard";
import { UserLightNFTCard } from "../../Components/UserLightNFTCard";

import { UserLightNFTThunk } from "../../Store/Thunks/UserLightNFTThunk";

import { UserLightDefiThunk } from "../../Store/Thunks/UserLightDefiThunk";

import { UserLightDefiCard } from "../../Components/UserLightDefiCard";

export const UserInfo = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const loadingTitle = useRef("");

  useEffect(() => {
    if (id) {
      dispatch(getUserShieldsThunk(id));
      dispatch(getDefishieldThunk(id));
    }
  }, [id]);

  const { usersDefiShields, userShieldStates, userShieldSuccess } = useSelector(
    (state) => state.UserCardsReducer
  );

  const userCounterpartyRiskResult = useSelector((state) => {
    return state.UserCounterpartyRiskReducer.userCounterpartyRiskResult;
  });

  const userLightNTFResult = useSelector((state) => {
    return state.UseLightNTFReducer.userLightNTFResult;
  });

  const useLightDefiResult = useSelector((state) => {
    return state.UseLightDefiReducer.userLightDefiResult;
  });

  const AbleBtn = (type) => {
    loadingTitle.current = type;
    if (id) {
      dispatch(userShieldThunk(+id, type, true));
    }
  };

  const DisableBtn = (type) => {
    loadingTitle.current = type;
    if (id) {
      dispatch(userShieldThunk(+id, type, false));
    }
  };

  useMemo(() => {
    if (userShieldSuccess) {
      dispatch(getUserShieldsThunk(id));
    }
  }, [userShieldSuccess]);

  useEffect(() => {
    dispatch(UserCounterpartyRiskThunk(id));
  }, []);

  useEffect(() => {
    dispatch(UserLightNFTThunk(id));
  }, []);

  useEffect(() => {
    dispatch(UserLightDefiThunk(id));
  }, []);

  return (
    <div className="main_box_right">
      <div className="main_box_right_header">
        <h2 className="user_shield_box_block_text">{state?.name}</h2>
      </div>
      <Tabs defaultActiveKey={"2"} centered>
        <TabPane
          disabled={!userShieldStates?.deFiShield}
          tab={
            <div className="tab_title_box">
              <ShieldBox
                AbleBtn={AbleBtn}
                DisableBtn={DisableBtn}
                loadingTitle={loadingTitle}
                title={"DeFi Shield"}
                text={"deFiShield"}
              />
            </div>
          }
          key="2"
        >
          <div className="tab_pane_deFi_shield">
            {usersDefiShields?.length ? (
              usersDefiShields?.map((item) => (
                <DefiShieldCard key={item.id} item={item} />
              ))
            ) : (
              <span className="no_date_text">No Data</span>
            )}
          </div>
        </TabPane>
        <TabPane
          disabled={!userShieldStates?.lightDeFi}
          tab={
            <div className="tab_title_box">
              <ShieldBox
                AbleBtn={AbleBtn}
                DisableBtn={DisableBtn}
                loadingTitle={loadingTitle}
                title={"Light DeFi"}
                text={"lightDeFi"}
              />
            </div>
          }
          key="3"
        >
          <div className="tab_pane_deFi_shield">
            {useLightDefiResult?.length ? (
              useLightDefiResult?.map((item) => (
                <UserLightDefiCard key={item.id} item={item} />
              ))
            ) : (
              <span className="no_date_text">No Data</span>
            )}
          </div>
        </TabPane>

        <TabPane
          disabled={!userShieldStates?.walletShield}
          tab={
            <ShieldBox
              AbleBtn={AbleBtn}
              DisableBtn={DisableBtn}
              loadingTitle={loadingTitle}
              title={"Counterparty Risk"}
              text={"partnersGuard"}
              onClick={() => {}}
            />
          }
          key="5"
        >
          <div className="tab_pane_deFi_shield">
            {userCounterpartyRiskResult?.length ? (
              userCounterpartyRiskResult?.map((item) => (
                <UserCounterpartyRiskCard key={item.id} item={item} />
              ))
            ) : (
              <span className="no_date_text">No Data</span>
            )}
          </div>
        </TabPane>

        <TabPane
          disabled={!userShieldStates?.lightNFT}
          tab={
            <div className="tab_title_box">
              <ShieldBox
                AbleBtn={AbleBtn}
                DisableBtn={DisableBtn}
                loadingTitle={loadingTitle}
                title={"Light NFT"}
                text={"lightNFT"}
              />
            </div>
          }
          key="4"
        >
          <div className="tab_pane_deFi_shield">
            {userLightNTFResult?.length ? (
              userLightNTFResult?.map((item) => (
                <UserLightNFTCard key={item.id} item={item} />
              ))
            ) : (
              <span className="no_date_text">No Data</span>
            )}
          </div>
        </TabPane>

        <TabPane
          disabled={!userShieldStates?.walletShield}
          tab={
            <ShieldBox
              AbleBtn={AbleBtn}
              DisableBtn={DisableBtn}
              loadingTitle={loadingTitle}
              title={"Custody Shield"}
              text={"walletShield"}
            />
          }
          key="1"
        >
          <span className="no_date_text">No Data</span>
        </TabPane>
      </Tabs>
    </div>
  );
};
